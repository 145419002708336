<template>
  <div class="experiences" data-view>
    <hero-with-image
      title="mindfulness through food"
      description="<p>mindfulness of food based on portioned meal sets using seasonal fresh produce from local farms, minimizing carbon footprint & food waste from the beginning of the process to the end.</p>"
      class="mb-18 lg:mb-20 lg:mt-8"
      :imageUrl="require('@/assets/eat-drinks/eat-and-drinks.png')"
    />
    <!-- <item-navigation :items="navigations" default-tab="season-restaurant" /> -->
    <neera-grid>
      <neera-grid-row data-aos="fade-up">
        <neera-detail-row
          :classImage="`img-para w-full rounded-tr-np-lg`"
          :imageUrl="require('@/assets/eat-drinks/EATDRINKS_01-season.png')"
        >
          <p id="season-restaurant" class="title text-np-2xl lg:text-np-3xl">
            season<sup class="text-np-base lg:text-np-xl">สีสัน</sup>
          </p>
          <p
            class="robototext text-np-green font-light text-np-base content-para"
          >
            <span class="saoltext whitespace-nowrap">‘season by neera’</span>,
            offers a different approach to Thai cuisine with seasonally crafted
            menus and portion-based sets, while fully devoted to eco-friendly
            ways from partnering with local farmers and suppliers minimizing any
            carbon footprint, to internal food waste procedures and selecting
            all environmentally friendly packaging for delivery and take-aways.
          </p>

          <div class="space-y-4 text-np-green text-np-base font-light">
            <div>
              <a
                id="button1"
                class="flex saoltext text-np-glimpse cursor-pointer"
                @click="
                  downloadFile(
                    'neeraretreathotel-season-breakfast-menu'
                  )
                "
                ><p class="saoltext">breakfast menu</p>
                <div class="flex transition duration-200 anchor-arrow px-2">
                  <img src="@/assets/neera-arrow.svg" /></div
              ></a>
            </div>
            <div>
              <a
                id="button1"
                class="flex saoltext text-np-glimpse cursor-pointer"
                @click="
                  downloadFile(
                    'neeraretreathotel-season-lunch-dinner-menu'
                  )
                "
                ><p class="saoltext">lunch and dinner set menu</p>
                <div class="flex transition duration-200 anchor-arrow px-2">
                  <img src="@/assets/neera-arrow.svg" /></div
              ></a>
            </div>
            <p class="text-np-sm">
              * menus are subject to change without notice.
            </p>
            <div>
              <neera-outline-button
                text="book a table"
                :linkTo="false"
                tel="tel:+66(099)4098484"
              />
            </div>
          </div>
        </neera-detail-row>
      </neera-grid-row>
      <neera-grid-row data-aos="fade-up">
        <neera-detail-row
          :classImage="`img-para w-full${index == 0 ? 'rounded-tr-np-lg' : ''}`"
          :imageUrl="
            require('@/assets/eat-drinks/EATDRINKS_02-neeraslowbar.png')
          "
        >
          <p id="ripple-cafe" class="title text-3xl lg:text-5xl">
            neera slow bar
          </p>
          <p
            class="robototext text-np-green font-light text-np-base content-para"
          >
            an eco-mindful slow bar offering
            <span class="saoltext">‘neera’</span> signature menus and specialty
            coffee beans from Nan, Thailand. enjoy the mindfulness of each
            brewing process while experiencing present moments through every
            sip. teas, fresh juices, craft sodas, mocktails, and pastries are
            also available.
          </p>

          <div class="space-y-4 text-np-green text-np-base font-light">
            <div>
              <a
                id="button1"
                class="flex saoltext text-np-glimpse"
                @click="
                  downloadFile(
                    'neeraretreathotel-slowbar-menu'
                  )
                "
                ><p class="saoltext">menu</p>
                <div class="flex transition duration-200 anchor-arrow px-2">
                  <img src="@/assets/neera-arrow.svg" /></div
              ></a>
            </div>
            <p class="text-np-sm">
              * menus are subject to change without notice.
            </p>
            <div>
              <neera-outline-button
                text="book a table"
                :linkTo="false"
                tel="tel:+66(099)4098484"
              />
            </div>
          </div>
        </neera-detail-row>
      </neera-grid-row>
    </neera-grid>
    <quote-break
      data-aos="fade-up"
      class="my-18 lg:my-20"
      title="local seasonal ingredients"
      description="we select local ingredients that are in season not only to bring out the best tastes but offer fresher and deliciously ripe produce that extremely helps in reducing carbon footprint and also supports to create a connection with the local community and economy to thrive along together."
    />
    <photo-horizon
      data-aos="fade-up"
      class="my-18 lg:my-20 mx-4 lg:mx-8"
      :photos="images"
    />
    <special-item title-list="special offers" />
  </div>
</template>

<script>
import SpecialItem from "@/components/SpecialItem.vue";
// import BookingTable from "@/components/BookingTable.vue";
import QuoteBreak from "../../components/QuoteBreak.vue";
import PhotoHorizon from "../../components/PhotoHorizon.vue";
// import ItemNavigation from "@/components/ItemNavigation.vue";
import NeeraGrid from "../../components/layout/NeeraGrid.vue";
import NeeraGridRow from "../../components/layout/NeeraGridRow.vue";
import NeeraDetailRow from "../../components/layout/NeeraDetailRow.vue";
import NeeraOutlineButton from "../../components/NeeraOutlineButton.vue";
import AOS from "aos";
import HeroWithImage from "../../components/HeroWithImage.vue";
import { getStaticFileBySlug } from "@/api/endpoints/staticfiles";
import to from "await-to-js";

export default {
  components: {
    SpecialItem,
    // BookingTable,
    QuoteBreak,
    PhotoHorizon,
    // ItemNavigation,
    NeeraGrid,
    NeeraGridRow,
    NeeraDetailRow,
    NeeraOutlineButton,
    HeroWithImage,
  },
  name: "Eat & Drink",
  created() {
    const title = "mindfulness through food";
    const description = "mindfulness of food based on portioned meal sets using seasonal fresh produce from local farms, minimizing carbon footprint & food waste from the beginning of the process to the end.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
  },
  data() {
    return {
      navigations: [
        {
          name: "season restaurant",
          link: "season-restaurant",
        },
        {
          name: "ripple café",
          link: "ripple-cafe",
        },
      ],
      images: [
        {
          image_url: require("@/assets/eat-drinks/EATDRINKS_03-localingredients_01.png"),
        },
        {
          image_url: require("@/assets/eat-drinks/EATDRINKS_03-localingredients_02.png"),
        },
        {
          image_url: require("@/assets/eat-drinks/EATDRINKS_03-localingredients_03.png"),
        },
      ],
    };
  },
  methods: {
    async downloadFile(slug) {
      const [err, response] = await to(getStaticFileBySlug(slug))

      // const url = response.data.data[0].attributes.file.data.attributes.url

      // File is deprecade, use content instead
      var url
      if(response.data.data[0].attributes.content.length > 0){
        // IF component is File get url from file
        if(response.data.data[0].attributes.content[0].__component === "static-file.upload-file"){
          url = response.data.data[0].attributes.content[0].file.data.attributes.url
        }else{ //IF component is url get data from field url
          url = response.data.data[0].attributes.content[0].url
        }
      }else{
         url = response.data.data[0].attributes.file.data.attributes.url
      }
      
      if (this.$vssWidth >= 1024) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #905235;
}

.title sup {
  top: -20px;
}

.booking_button {
  color: #994e2e;
  border: 1px solid #994e2e;
  border-radius: 10px;
}

.menu-content {
  border-top: 1px solid #cecdc8;
}

a:hover .anchor-arrow {
  transform: translateX(0.25rem);
}
</style>
